@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1140px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.listingCard {
  width: 100%;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.cardModel {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  transition: 0.2s;

  @media (--viewportMedium) {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 3px 17px rgba(0, 0, 0, 0.22);

    }
  }
}